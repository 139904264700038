<template>
		<div class="close">
<!--				<router-link :to="{name: 'Home'}"><i class="icon-close"></i></router-link>-->
				<a href="inapp://close">
						<i class="icon-close"></i>
				</a>
<!--				<router-link to="inapp://close"><i class="icon-close"></i></router-link>-->
<!--				<button @click="inapp://close"><i class="icon-close"></i></button>-->
		</div>
</template>

<script>
export default {}
</script>
