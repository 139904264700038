import awsConfigProd from './aws-exports.prod'
import awsConfig from './aws-exports'

const prodDomains = [
    'https://special-offer.waterguru.com',
    'https://special-offer.waterguru-prod.com'
]

const MODE = (~prodDomains.indexOf(location.origin) && process.env.NODE_ENV === 'production') ? 'prod' : 'qa'
const CONFIG = (MODE === 'prod') ? awsConfigProd : awsConfig
const PREFIX = (MODE === 'prod') ? 'prod-' : 'qa-'

export { PREFIX, MODE }
export default CONFIG