<template>
		<div id="app">
				<router-view/>
		</div>
</template>

<script>

	export default {
			watch: {
					'$route': {
							handler: (to, from) => {
									document.title = to.meta.title || 'WaterGuru'
							},
							immediate: true
					}
			}
	}
</script>
