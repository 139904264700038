<template>
		<!-- Begin Site -->
		<div class="wrapper">
				<!-- Preloader -->
				<Preloader/>
				<!-- X Button-->
				<close/>
				<!-- content -->
				<main class="content home">
						<div class="container-fluid">
								<div class="content__head">
										<h5 class="content__toptitle">THE BETTER WAY TO MANAGE YOUR POOL.</h5>
										<h2 class="content__title">AUTOMATICALLY.</h2>
								</div>
								<div class="content__body">
										<div class="content__info">
												<p class="content__info-title"><strong class="content__large-text">Love SENSE?</strong> There’s
														even more. WaterGuru TREAT:</p>
												<ul class="list">
														<li class="list__item"><strong>Measures</strong> and tracks real time pool chemistry
																conditions.
														</li>
														<li class="list__item"><strong>Dispenses</strong> the least amount of chemicals only when
																needed.
														</li>
														<li class="list__item"><strong>Balances</strong> pH and Chlorine perfectly 24/7 to control
																bacteria and algae.
														</li>
														<li class="list__item"><strong>Works</strong> with all chlorine types and salt water pools.
														</li>
												</ul>
										</div>
										<div class="get-treat">
												<picture class="get-treat__thumb">
														<source media="(min-width: 992px)" srcset="../assets/images/thumb.png">
														<source media="(min-width: 320px)" srcset="../assets/images/thumb.png">
														<img class="w-100" src="../assets/images/thumb.png" alt="">
												</picture>
												<div class="get-treat__info">
														<h3 class="get-treat__title">Get TREAT</h3>
														<span class="get-treat__free">FREE</span>
														<h3 class="get-treat__price"><small>$</small>795 Value</h3>
														<ul class="mt-2 get-treat__list">
																<li class="get-treat__item">No credit card needed.</li>
																<li class="get-treat__item"> No commitments.</li>
																<li class="get-treat__item">No hidden fees.</li>
														</ul>
												</div>
										</div>
										<a class="mt-7 btn btn-secondary" href="/treat-free-upgrade">LEARN MORE</a>
								</div>
						</div>
				</main>
				<!-- ./End content -->
		</div>
		<!-- ./End Site Wrapper -->
</template>

<script>
	import Close from '../components/CloseButton';
	import Preloader from '../components/Preloader';

	import LazyLoad from 'vanilla-lazyload';
	import lambda from '../aws/lamdba'
	import Auth from "@aws-amplify/auth";

	export default {
			components: {
					Close,
					Preloader
			},
			data() {
					return {
							response: null,
					}
			},
			async created (){
					const payload = {offerId: '2021_TREAT', eventType: 'VIEW'}
					lambda('postSpecialOfferEvent', await this.addValues(payload))
							.then(data => {
									this.response = data
							})
			},
			mounted() {
					let lazy = new LazyLoad()
					window.addEventListener('klaviyoForms', function (e) {
							let _learnq = _learnq || [];
							_learnq.push(['identify', {
									'$email': '{{ user.attributes.email }}',
									'$first_name': '{{ user.attributes.given_name }}',
									'$last_name': '{{ user.attributes.family_name }}'
							}]);
					})
			},
			methods: {
					async addValues(payload, VALUE) {
							const user = await Auth.currentAuthenticatedUser()
							const VARS = {
									USER_ID: user.username,
									VALUE: VALUE
							}
							return JSON.parse(Object.keys(VARS).reduce((str, key) => {
									return str.replace(`[${key}]`, VARS[key])
							}, JSON.stringify(payload)))
					}
			}
	}
</script>
