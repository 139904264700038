<template>
		<!-- Begin Site -->
		<div class="wrapper">
				<!-- Preloader -->
				<Preloader/>
				<!-- X Button-->
				<close/>
				<!-- content -->
				<main class="content content--thanks">
						<div class="container-fluid">
								<div class="content__head">
										<h2 class="content__title">THANK YOU.</h2>
										<h5 class="content__subtitle">TREAT IS ON THE WAY SOON</h5>
								</div>
								<div class="mt-9 content__info">
										<h4 class="content__info-title">Here’s what happens next</h4>
										<ul class="list">
												<li class="list__item"> Be on the lookout for the email from WaterGuru to schedule TREAT
														installation.
												</li>
												<li class="list__item">A WaterGuru technician will deliver and install TREAT, set everything up,
														and answer any questions.
												</li>
												<li class="list__item">Enjoy safe and clear pool water, hassle free.</li>
										</ul>
								</div>
								<div class="contact">
										<p class="contact__text"><a class="contact__link" href="#">Questions?</a> Contact Support<a
												class="contact__link" href="mailto:support@waterguru.com">support@waterguru.com</a></p>
								</div>
						</div>
				</main>
				<!-- ./End content -->
		</div>
		<!-- ./End Site Wrapper -->
</template>

<script>
	import Close from '../components/CloseButton';
	import Preloader from '../components/Preloader';

	import lambda from "@/aws/lamdba";
	import Auth from "@aws-amplify/auth";

	export default {
			components: {
					Close,
					Preloader
			},
			async created() {
					const payload = {offerId: '2021_TREAT', eventType: 'ACCEPT'}
					lambda('postSpecialOfferEvent', await this.addValues(payload))
							.then(data => {
									this.response = data
							})
			},
			methods: {
					async addValues(payload, VALUE) {
							const user = await Auth.currentAuthenticatedUser()
							const VARS = {
									USER_ID: user.username,
									VALUE: VALUE
							}
							return JSON.parse(Object.keys(VARS).reduce((str, key) => {
									return str.replace(`[${key}]`, VARS[key])
							}, JSON.stringify(payload)))
					}
			}
	}
</script>
