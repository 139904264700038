<template>
		<div v-if="load" :class="`preloader ${$route.name === 'Home' ? 'home' : ''}`">
				<div class="lds-ring">
						<div></div>
						<div></div>
						<div></div>
						<div></div>
				</div>
		</div>
</template>

<script>
	export default {
			name: "Preloader",
			data() {
					return {
							load: true
					}
			},
			mounted() {
					this.load = true
					setTimeout(() => {
							this.load = false;
					}, 2000)
			}
	}
</script>
