import Auth from '@aws-amplify/auth';

export default {
    state: {
        user: {},
        attributes: []
    },

    mutations: {
        setUser(state, {user}) {
            state.user = user
        },
        setAttributes(state, {attr}) {
            state.attributes = attr
        }
    },

    actions: {
        fetchEmail({commit}) {
            return new Promise((resolve, reject) => {
                Auth.currentAuthenticatedUser().then(user => {
                    commit('setUser', {user})
                    Auth.currentUserInfo().then(user => {
                        commit('setAttributes', {attr: user.attributes})
                        resolve()
                    }).catch(err => {
                        commit('setAttributes', {attr: {}})
                        reject(err)
                    })
                }).catch((err) => {
                    commit('setUser', {user: {}})
                    reject(err)
                })
            })
        }
    },

    getters: {
        getUser(state) {
            return state.user
        },
        getUserId(state) {
            return state.user ? state.user.username : null
        },
        getAttributes(state) {
            return state.attributes
        }
    }
}