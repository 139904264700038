import Amplify from '@aws-amplify/core'
import CONFIG from './config'
import lambda from './lamdba'

const ENDPOINT = `https://lambda.${CONFIG.region}.amazonaws.com/2015-03-31/functions/`
const REGION = CONFIG.region

Amplify.configure({
    Auth: CONFIG,
    API: {
        endpoints: [
            {
                name: 'lambda',
                endpoint: ENDPOINT,
                service: 'lambda',
                region: REGION
            }
        ]
    }
})

export { lambda, CONFIG }
