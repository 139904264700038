<template>
		<!-- Begin Site -->
		<div class="wrapper">
				<!-- Preloader -->
				<Preloader/>
				<!-- X Button-->
				<close/>
				<!-- content -->
				<main class="content">
						<div class="container-fluid">
								<div class="content__head">
										<h2 class="content__title">TREAT FREE <br> UPGRADE.</h2>
								</div>
								<div class="content__body">
										<p class="mt-4 content__desc">Confirm the phone number you’d like to use.</p>
										<div id="klaviyo-form" class="klaviyo-form-RzZtwz"></div>
										<div class="mt-6 content__info">
												<h4 class="content__info-title"><span>TREAT</span> upgrade includes</h4>
												<ul class="list">
														<li class="list__item"><span>FREE TREAT</span> smart device – a $795 value. Replaces SENSE
																on the skimmer.
														</li>
														<li class="list__item"><span>FREE 90</span> - day white-glove service includes installation,
																Smart Cassettes, chemicals, service visits.
														</li>
														<li class="list__item"><span> After 90</span> days, keep TREAT for FREE or return it.
																Purchase chemicals and Smart Cassettes through WaterGuru to continue TREAT operation.
														</li>
												</ul>
										</div>
										<router-link class="mt-7 btn btn-transparent" :to="{name: 'Free Upgrade'}">Not interested at this
												time
												<i class="icon-arrow-next"></i>
										</router-link>
								</div>
						</div>
				</main>
				<!-- ./End content -->
		</div>
		<!-- ./End Site Wrapper -->
</template>

<script>
	import Close from '../components/CloseButton';
	import Preloader from '../components/Preloader';

	export default {
			components: {
					Close,
					Preloader
			},
			data() {
					return {
							form: {
									phone_number: ''
							}
					}
			},
			mounted() {
					window.addEventListener('klaviyoForms', function (e) {
							let _learnq = _learnq || [];
							_learnq.push(['identify', {
									'$email': '{{ user.attributes.email }}',
									'$first_name': '{{ user.attributes.given_name }}',
									'$last_name': '{{ user.attributes.family_name }}'
							}]);

							if (e.detail.type == 'submit') {
									window.location.replace('/thanks')
							}
					})
			}
	}
</script>
