import API from "@aws-amplify/api";
import {PREFIX} from "./config";
import {version} from '../../package.json'
import Auth from "@aws-amplify/auth";

export default async (name, body = {}, method = "post") => {
    const user = await Auth.currentAuthenticatedUser()
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    const path = PREFIX + name + "/invocations";
    body.reqUserId = user.username;
    body.clientType = "WEB_VIEW";
    body.clientView = "SPECIAL_OFFER";
    body.clientVersion = version;
    body.forClientType =
        navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform)
            ? "IOS_APP"
            : "ANDROID_APP";
    return API[method]("lambda", path, {body});
};