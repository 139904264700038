import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import './aws'
import './sass/index.scss';
import VueCookie from 'vue-cookie';


Vue.config.productionTip = false

Vue.use(VueCookie)

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
