<template>
		<!-- Begin Site -->
		<div class="wrapper">
				<!-- X Button-->
				<close/>
				<!-- content -->
				<main class="content content--sign-up">
						<div class="container-fluid">
								<div class="content__head">
										<h2 class="content__title">FREE TREAT <br> UPGRADE</h2>
								</div>
								<div class="content__body">
										<p class="mt-6 content__desc">I do not want to take advantage of the FREE upgrade to TREAT program.
												I understand this one-time offer ends soon. Remove me from the exclusive list.</p>
										<div class="d-grid btn-wrap">
												<router-link class="btn btn-primary btn-sm" :to="{name: 'Opt Out Confirmation'}"
																		 @click.native="trackReject">
														DECLINE FREE UPGRADE
												</router-link>
												<a class="btn btn-blue-gradient btn-sm" href="/treat-free-upgrade">
														GIVE TREAT A TRY
												</a>
										</div>
								</div>
								<router-link class="mt-7 btn btn-transparent" :to="{name: 'Home'}">LEARN MORE ABOUT TREAT
										<i class="ms-4 icon-arrow-next"></i>
								</router-link>
						</div>
				</main>
				<!-- ./End content -->
		</div>
		<!-- ./End Site Wrapper -->
</template>

<script>
	import Close from "../components/CloseButton";
	import {lambda} from '../aws'
	import Auth from "@aws-amplify/auth";

	export default {
			components: {
					Close
			},
			data() {
					return {
							response: null
					}
			},
			methods: {
					 trackReject() {
							var _learnq = _learnq || [];
							_learnq.push(['track', 'Decline Free Upgrade', {
									'Free Upgrade': true
							}]);
							this.Reject()
					},
					async Reject(){
							const payload = {offerId: '2021_TREAT', eventType: 'REJECT' }
							lambda('postSpecialOfferEvent', await this.addValues(payload))
									.then(data => {
											this.response = data
									})
					},
					async addValues(payload, VALUE) {
							const user = await Auth.currentAuthenticatedUser()
							const VARS = {
									USER_ID: user.username,
									VALUE: VALUE
							}
							return JSON.parse(Object.keys(VARS).reduce((str, key) => {
									return str.replace(`[${key}]`, VARS[key])
							}, JSON.stringify(payload)))
					}
			}
	}
</script>
