import Vue from 'vue';
import VueRouter from 'vue-router';

import Signup from '../views/sign-up-form';
import Home from '../views/Home';
import Thanks from '../views/thanks';
import FreeUpgrade from '../views/free-treat-upgrade';
import OptOut from '../views/opted-out';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/treat-free-upgrade',
        name: 'Treat Free Upgrade',
        component: Signup,
        meta: {title: 'Treat Free Upgrade'}
    },
    {
        path: '/thanks',
        name: 'Thanks',
        component: Thanks
    },
    {
        path: '/free-upgrade',
        name: 'Free Upgrade',
        component: FreeUpgrade,
        meta: {title: 'Free Upgrade'}
    },
    {
        path: '/opt-out',
        name: 'Opt Out Confirmation',
        component: OptOut,
        meta: {title: 'Opt Out Confirmation'}
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
