export default {
    // ================WaterGuru===================
    // REQUIRED - Amazon Cognito Identity Pool ID
    identityPoolId: 'us-west-2:547d2d68-9056-43d2-abe3-9cb91d0a7420',
    // REQUIRED - Amazon Cognito Region
    region: 'us-west-2',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-west-2_DqiqpNbRv',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '7j4imv74h01k662iqo92dci018',
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,
    // OPTIONAL - Configuration for cookie storage
    cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        domain: ~location.origin.indexOf('localhost') ? '.localhost.specialurl.com' : '.waterguru-qa.com',
        // OPTIONAL - Cookie path
        path: '/',
        // OPTIONAL - Cookie expiration in days
        expires: 365,
        // OPTIONAL - Cookie secure flag
        secure: location.origin.indexOf('localhost') === -1
    }
}
