<template>
		<!-- Begin Site -->
		<div class="wrapper">
				<!-- content -->
				<main class="content content--thanks">
						<div class="container-fluid">
								<div class="content__head">
										<h2 class="content__title">OPT OUT CONFIRMATION</h2>
								</div>
								<div class="content__body">
										<p class="mt-7 content__desc">You opted out of the WaterGuru TREAT upgrade program. Please feel free
												to reach out to us at <a class="content__link" href="mailto:support@waterguru.com">support@waterguru.com</a>
												at anytime if you change your mind.</p>
								</div>
								<close/>
						</div>
				</main>
				<!-- ./End content -->
		</div>
		<!-- ./End Site Wrapper -->
</template>

<script>
	import Close from '../components/CloseButton';

	export default {
			components: {
					Close
			}
	}
</script>
